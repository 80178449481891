import { types } from "mobx-state-tree"

export const Firm = types
    .model({
        firmId: types.optional(types.number, 0),
        firmName: types.optional(types.string, ""),
        firmType: types.optional(types.number, 0),
        iban: types.optional(types.string, ""),
        bankName: types.optional(types.string, ""),
        bankUsername: types.optional(types.string, ""),
        taxNo: types.optional(types.string, ""),
        taxAdministration: types.optional(types.string, ""),
        invoiceAddress: types.optional(types.string, "")
    }).actions(self => ({

    }))