import React, { Component } from 'react'
import { observer, inject } from "mobx-react"

import { Card, CardActions, CardContent, CardHeader } from '@mui/material';
import { LeftMenu } from '../../components/common/LeftMenu';

import { Box, Container, Grid } from '@mui/material';

import dayjs from 'dayjs';
import { ProfileMenu } from '../../components/tetkik/profile/ProfileMenu';

class ProfilePage extends Component {

    constructor(props) {
        super(props)
        this.state = {
            dashboardStatics: null,
            todayAppointmentList: []
        }
    }

    componentDidMount = async () => {
        if (!this.props.store.isLogin) {
            this.props.store.setLoadingBarStatus(true)
            this.props.history.push("/lab/kullanici-girisi");
        } else {

        }
    }

    render() {

        return (
            <div className="container mps-container">
                <LeftMenu activePage={"Profilim"} />
                <Box component="main" sx={{
                    flexGrow: 1,
                    py: 8
                }} style={{ width: "calc(100% - 250px)", marginLeft: "250px", marginTop: "20px" }} >
                    <Container maxWidth={false}>
                        <Grid container spacing={3}>
                            <Grid item lg={12} md={12} xl={12} xs={12}>
                                <Card style={{ height: '100%' }}>
                                    <CardContent style={{ height: 'calc(100vh - 250px)' }}>
                                        <ProfileMenu />
                                    </CardContent>
                                </Card>
                            </Grid>
                        </Grid>
                    </Container>
                </Box>
            </div>
        )
    }
}

export default inject("store")(observer(ProfilePage));
