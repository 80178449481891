import { Firm } from '../../../model/Firm'

/**
 * This method transform Login API response doc to User
 * @param {*} userResponse 
 */
const parseFirmData = (firmResponse) => {

    console.log("firmResponse: ", firmResponse)
    let firm = null
    try {

        firm = Firm.create({
            firmId: firmResponse.data.firmId,
            firmName: firmResponse.data.firmName,
            firmType: firmResponse.data.firmType,
            iban: firmResponse.data.iban,
            bankName: firmResponse.data.bankName,
            bankUsername: firmResponse.data.bankUsername,
            taxNo: firmResponse.data.taxNo,
            taxAdministration: firmResponse.data.taxAdministration,
            invoiceAddress: firmResponse.data.invoiceAddress
        })

    } catch (error) {
        console.log("error: ", error)
    }

    return firm;
}

export default parseFirmData;