import { Avatar, Card, CardContent, Grid, Typography } from '@mui/material';
import PersonIcon from '@mui/icons-material/Person';
import CurrencyFormat from 'react-currency-format';

export const BekleyenTetkik = (props) => (
  <Card
    sx={{ height: '100%' }}
    {...props}
  >
    <CardContent>
      <Grid container spacing={3} sx={{ justifyContent: 'space-between' }}>
        <Grid item>
          <Typography color="textSecondary" gutterBottom variant="overline">
            BEKLEYEN <br/> TETKİK
          </Typography>
          <Typography color="textPrimary" variant="h4" >
             <CurrencyFormat value={props.data} displayType="text" thousandSeparator={true} />
          </Typography>
        </Grid>
        <Grid item>
          <Avatar
            sx={{
              backgroundColor: 'error.main',
              height: 56,
              width: 56
            }}>
            <PersonIcon />
          </Avatar>
        </Grid>
      </Grid>
    </CardContent>
  </Card>
);
