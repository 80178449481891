import React, { Component } from 'react'
import { observer, inject } from "mobx-react"

import { Card, CardActions, CardContent, CardHeader, TextField, Typography, Box, Container, Grid, Table, TableHead, TableBody, TableRow, TableCell } from '@mui/material';
import { LeftMenu } from '../../components/common/LeftMenu';
import Chip from '@mui/material/Chip';

import dayjs from 'dayjs';

import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import Checkbox from '@mui/material/Checkbox';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import PrintIcon from '@mui/icons-material/Print';

import Upload from '../../components/upload/Upload'
import MapissoAlert from '../../components/common/MapissoAlert';

import getLabFirmTetkikById from '../../utils/api/tetkik/GetLabFirmTetkikById'
import putLabFirmTetkikRequestUpdate from '../../utils/api/tetkik/PutLabFirmTetkikRequestUpdate'

import ReactToPrint from 'react-to-print';

function not(a, b) {
    return a.filter((value) => b.indexOf(value) === -1);
}

function intersection(a, b) {
    return a.filter((value) => b.indexOf(value) !== -1);
}

function union(a, b) {
    return [...a, ...not(b, a)];
}

class TetkikTalepBilgiPage extends Component {

    constructor(props) {
        super(props)
        this.state = {
            tetkik: null,
            checked: [],
            left: [],
            right: [],
            fileUrl: "",
            uploadTetkikItems: [],
            talepListeFiyat: 0,
            talepGerceklesenFiyat: 0,
            discountRate: 0,
            tetkikItems: []
        }
    }

    componentDidMount = async () => {
        if (!this.props.store.isLogin) {
            this.props.store.setLoadingBarStatus(true)
            this.props.history.push("/lab/kullanici-girisi");
        } else {

            await this.getTetkikInfoRequest()
        }
    }

    getTetkikInfoRequest = async () => {
        this.props.store.setLoadingBarStatus(false)
        let getLatTetkikInfoResponse = await getLabFirmTetkikById(this.props.store.baseUrl, this.props.store.token, this.props.store.user[0].id, this.props.store.firm[0].firmId,
            this.props.match.params.id)

        if (getLatTetkikInfoResponse !== null && getLatTetkikInfoResponse !== undefined) {
            if (getLatTetkikInfoResponse.status === 200) {
                if (getLatTetkikInfoResponse.data !== null) {
                    this.setState({
                        tetkik: getLatTetkikInfoResponse.data[0]
                    })

                    if (getLatTetkikInfoResponse.data[0].items !== null) {
                        let items = getLatTetkikInfoResponse.data[0].items
                        let actualTotalPrize = 0
                        let estimatedTotalPrize = 0
                        for (let index = 0; index < items.length; index++) {
                            const item = items[index];
                            actualTotalPrize += item.actualPrice
                            estimatedTotalPrize += item.estimatedPrice
                        }

                        this.setState({
                            tetkikItems: items,
                            talepListeFiyat: (actualTotalPrize !== 0) ? actualTotalPrize: estimatedTotalPrize ,
                            talepGerceklesenFiyat: (actualTotalPrize !== 0) ? actualTotalPrize: estimatedTotalPrize
                        })

                        let itemsCompleted = items.filter(function (e) { return e.examStatus === 3 })
                        items = items.filter(function (e) { return e.examStatus !== 3 })
                        this.setState({
                            left: items,
                            uploadTetkikItems: itemsCompleted
                        })
                    }
                } else {
                    this.props.store.setMessage("Tetkik bilgisi getirilirken hata oluştu.", true, "error")
                }
            } else if (getLatTetkikInfoResponse.status === 401) {
                this.props.store.setUserLogout()
                this.props.history.push("/lab/kullanici-girisi")
            } else {
                this.props.store.setMessage("Tetkik bilgisi getirilirken hata oluştu.", true, "error")
            }
        }
        this.props.store.setLoadingBarStatus(true)
    }

    leftChecked = () => {
        return intersection(this.state.checked, this.state.left)
    }
    rightChecked = () => {
        return intersection(this.state.checked, this.state.right)
    }

    numberOfChecked = (items) => intersection(this.state.checked, items).length;

    handleToggle = (value) => () => {
        const currentIndex = this.state.checked.indexOf(value);
        const newChecked = [...this.state.checked];

        if (currentIndex === -1) {
            newChecked.push(value);
        } else {
            newChecked.splice(currentIndex, 1);
        }

        this.setState({
            checked: newChecked
        })
    };

    handleToggleAll = (items) => () => {
        if (this.numberOfChecked(items) === items.length) {
            this.setState({
                checked: not(this.state.checked, items)
            })
        } else {
            this.setState({
                checked: union(this.state.checked, items)
            })
        }
    };

    handleCheckedRight = () => {
        let leftCheckedResult = this.leftChecked()
        this.setState({
            right: this.state.right.concat(leftCheckedResult),
            left: not(this.state.left, leftCheckedResult),
            checked: not(this.state.checked, leftCheckedResult)
        })
    };

    handleCheckedLeft = () => {
        let rightCheckedResult = this.rightChecked()
        this.setState({
            left: this.state.left.concat(rightCheckedResult),
            right: not(this.state.right, rightCheckedResult),
            checked: not(this.state.checked, rightCheckedResult)
        })
    };

    customList = (title, items) => (
        <Card>
            <CardHeader
                sx={{ px: 2, py: 1 }}
                avatar={
                    <Checkbox
                        onClick={this.handleToggleAll(items)}
                        checked={this.numberOfChecked(items) === items.length && items.length !== 0}
                        indeterminate={this.numberOfChecked(items) !== items.length && this.numberOfChecked(items) !== 0}
                        disabled={items.length === 0}
                        inputProps={{
                            'aria-label': 'tüm tetkikler seçildi',
                        }}
                    />
                }
                title={title}
                subheader={`${this.numberOfChecked(items)}/${items.length} seçildi`}
            />
            <Divider />
            <List sx={{
                width: "100%",
                height: 230,
                bgcolor: 'background.paper',
                overflow: 'auto',
            }}
                dense
                component="div"
                role="list" >
                {items.map((value) => {
                    const labelId = `transfer-list-all-item-${value}-label`;

                    return (
                        <ListItem
                            key={value.examinationId}
                            role="listitem"
                            button
                            onClick={this.handleToggle(value)}
                        >
                            <ListItemIcon>
                                <Checkbox
                                    checked={this.state.checked.indexOf(value) !== -1}
                                    tabIndex={-1}
                                    disableRipple
                                    inputProps={{
                                        'aria-labelledby': labelId,
                                    }}
                                />
                            </ListItemIcon>
                            <ListItemText id={labelId} primary={value.productName + " (Fiyatı: " + value.estimatedPrice + " TL)"} />
                        </ListItem>
                    );
                })}
            </List>
        </Card>
    );

    onUploadFinished = async (fileUrl) => {
        console.log("fileUrl: ", fileUrl)
        let selectedTetkikArray = this.state.right
        let willUploadTetkikArray = [...this.state.uploadTetkikItems]
        let selectedTetkikArrayRemoved = [...this.state.uploadTetkikItems]

        for (let index = 0; index < selectedTetkikArray.length; index++) {
            const selectedTetkikTemp = selectedTetkikArray[index];

            let tempExamination = {
                examinationId: selectedTetkikTemp.examinationId,
                examFiles: [fileUrl],
                examStatus: 3,
                productName: selectedTetkikTemp.productName,
                actualPrice: (this.state.discountRate !== 0) ?
                    selectedTetkikTemp.estimatedPrice * (1 - this.state.discountRate) : selectedTetkikTemp.estimatedPrice
            }

            willUploadTetkikArray.push(tempExamination)

            selectedTetkikArrayRemoved = selectedTetkikArrayRemoved.filter(function (e) { return e.examinationId === selectedTetkikTemp.examinationId })
        }

        this.setState({
            uploadTetkikItems: willUploadTetkikArray,
            right: selectedTetkikArrayRemoved
        })

        this.props.store.setLoadingBarStatus(false)

        for (let index = 0; index < willUploadTetkikArray.length; index++) {
            const tetkikTemp = willUploadTetkikArray[index];
            delete tetkikTemp.productName
            try {
                let response = await putLabFirmTetkikRequestUpdate(this.props.store.baseUrl, this.props.store.token, this.props.store.user[0].id, tetkikTemp.examinationId, tetkikTemp)
                console.log("response: ", response)
            } catch (error) {
                console.log("error...");
            }
            console.log("axios request done");
        }

        await this.getTetkikInfoRequest()

        this.props.store.setLoadingBarStatus(true)
    }

    onChangeActualPrizeTextField = (event) => {
        this.setState({
            talepGerceklesenFiyat: event.target.value,
            discountRate: (1 - (event.target.value / this.state.talepListeFiyat))
        })

        console.log("talepGerceklesenFiyat: ", (1 - (event.target.value / this.state.talepListeFiyat)))
    }

    onClickTetkikIslemKapat = async () => {
        let fixedList = []

        if (this.state.left.length > 0) {
            for (let index = 0; index < this.state.left.length; index++) {
                const selectedTetkikTemp = this.state.left[index];
                console.log("tempLeft: ", selectedTetkikTemp)
                let tempExamination = {
                    examinationId: selectedTetkikTemp.examinationId,
                    examFiles: [""],
                    examStatus: 4,
                    productName: selectedTetkikTemp.productName,
                    actualPrice: (this.state.discountRate !== 0) ?
                        selectedTetkikTemp.estimatedPrice * (1 - this.state.discountRate) : selectedTetkikTemp.estimatedPrice
                }

                fixedList.push(tempExamination)
            }
        }

        if (this.state.tetkikItems.length > 0) {
            for (let index = 0; index < this.state.tetkikItems.length; index++) {
                const selectedTetkikTemp = this.state.tetkikItems[index];

                if (this.state.left.filter(e => e.examinationId === selectedTetkikTemp.examinationId).length > 0) {
                    /* vendors contains the element we're looking for */
                } else {
                    selectedTetkikTemp.actualPrice = (this.state.discountRate !== 0) ?
                        selectedTetkikTemp.estimatedPrice * (1 - this.state.discountRate) : selectedTetkikTemp.estimatedPrice
                    fixedList.push(selectedTetkikTemp)
                }
            }
        }

        this.props.store.setLoadingBarStatus(false)
        for (let index = 0; index < fixedList.length; index++) {
            const tetkikTemp = fixedList[index];
            delete tetkikTemp.productName
            try {
                let response = await putLabFirmTetkikRequestUpdate(this.props.store.baseUrl, this.props.store.token, this.props.store.user[0].id, tetkikTemp.examinationId, tetkikTemp)
                console.log("response: ", response)
            } catch (error) {
                console.log("error...");
            }
        }

        await this.getTetkikInfoRequest()
        this.props.store.setLoadingBarStatus(true)

    }
    printTetkikRequest = () => (
        <Grid id="printContent">
            <Grid container>
            <Grid item xs={6}>
                <Typography style={{ fontWeight: "bold" }}>TALEP EDİLEN TETKİKLER</Typography>
            </Grid>
            <Grid item xs={6} style={{ textAlign: "right" }}>
                <Typography variant="body2">
                Tarih: {dayjs(new Date()).format("DD.MM.YYYY")}
                </Typography>
            </Grid>
            </Grid>
            <Grid
            container
            style={{ width: "100%", border: "1px solid gray", padding: "10px" }}
            >
            <Grid item xs={5}>
                <Grid container spacing={1}>
                <Grid item xs={12}>
                    <Typography variant="body1" style={{ fontWeight: "bold" }}>
                    Doktor Bilgileri
                    </Typography>
                </Grid>
                <Grid item xs={5}>
                    <Typography variant="body2" style={{ fontWeight: "bold" }}>
                    Dr. Adı Soyadı:
                    </Typography>
                </Grid>
                <Grid item xs={7}>
                    <Typography variant="body2">
                        {this.state.tetkik.doctorName + " " + this.state.tetkik.doctorSurname}
                    </Typography>
                </Grid>
                <Grid item xs={5}>
                    <Typography variant="body2" style={{ fontWeight: "bold" }}>
                    Telefon Numarası:
                    </Typography>
                </Grid>
                <Grid item xs={7}>
                    <Typography variant="body2">
                    {this.state.tetkik.doctorMobilePhone}
                    </Typography>
                </Grid>
                </Grid>
            </Grid>
            <Grid item xs={1}></Grid>
            <Grid item xs={6}>
                <Grid container spacing={1}>
                <Grid item xs={12}>
                    <Typography variant="body1" style={{ fontWeight: "bold" }}>
                    Hasta Bilgileri
                    </Typography>
                </Grid>
                <Grid item xs={5}>
                    <Typography variant="body2" style={{ fontWeight: "bold" }}>
                    Hasta Adı Soyadı:
                    </Typography>
                </Grid>
                <Grid item xs={7}>
                    <Typography variant="body2">
                    {this.state.tetkik.patientName + " " + this.state.tetkik.patientSurname}
                    </Typography>
                </Grid>
                <Grid item xs={5}>
                    <Typography variant="body2" style={{ fontWeight: "bold" }}>
                    Telefon Numarası:
                    </Typography>
                </Grid>
                <Grid item xs={7}>
                    <Typography variant="body2">
                    {this.state.tetkik.patientMobilePhone}
                    </Typography>
                </Grid>
                </Grid>
            </Grid>
            </Grid>
            <Grid
            container
            spacing={0}
            style={{
                width: "100%",
                marginTop: "15px",
                border: "1px solid gray",
                padding: "10px",
            }}
            >
            <Table aria-label="simple table">
                <TableHead>
                <TableRow>
                    <TableCell>Tetkik İsmi</TableCell>
                    <TableCell>Fiyat</TableCell>
                </TableRow>
                </TableHead>
                <TableBody>
                {this.state.tetkikItems.map((value, idx) => (
                    <TableRow key={idx}>
                        <TableCell>
                            {value.productName}
                        </TableCell>
                        <TableCell>{value.estimatedPrice}TL</TableCell>
                    </TableRow>
                ))}
                <TableRow>
                <TableCell colSpan={6}>
                    <strong>Toplam: {this.state.tetkikItems.reduce((total, pay) => total + (pay?.estimatedPrice || 0), 0)}TL</strong>
                </TableCell>
                </TableRow>
                </TableBody>
            </Table>
            </Grid>
        </Grid>
    );

    render() {
        return (
            <div className="container mps-container">
                <LeftMenu activePage={"Tetkik Talep Bilgisi"} />
                <Box component="main" sx={{
                    flexGrow: 1,
                    py: 8
                }} style={{ width: "calc(100% - 250px)", marginLeft: "250px", marginTop: "20px" }} >
                    <Container maxWidth={false}>
                        <Grid container spacing={3}>
                            <Grid item lg={12} md={12} xl={12} xs={12}>
                                <Card style={{ height: '100%' }}>
                                    <CardHeader title="Tetkik Talep Bilgisi"
                                        style={{ paddingBottom: "10px" }} />
                                    <CardContent style={{ paddingTop: "0", height: 'auto' }}>
                                        <Grid container spacing={2} style={{ marginTop: "15px", borderTop: "1px solid #e0e0e0", borderBottom: "1px solid #e0e0e0", paddingBottom: "15px" }}>
                                            <Grid item xs={6}>
                                                <Typography><b>Talep Tarihi: </b> {(this.state.tetkik !== null) ? dayjs(this.state.tetkik.appointmentDate).format("DD.MM.YYYY HH:mm") : ""}</Typography>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <Typography style={{ fontWeight: "bold" }}>Talep Durumu:  </Typography>
                                                <Chip color='primary' label={(this.state.tetkik !== null) ? this.state.tetkik.statusName : ""} style={{ fontWeight: "bold" }} />
                                            </Grid>
                                            <Grid item xs={6}>
                                                <Typography><b>Hasta Adı Soyadı:</b> {(this.state.tetkik !== null) ? this.state.tetkik.patientName + " " + this.state.tetkik.patientSurname : ""}</Typography>
                                                <Typography style={{ marginTop: "10px" }}><b>Hasta Telefon Numarası:</b> {(this.state.tetkik !== null) ? this.state.tetkik.patientMobilePhone : ""}</Typography>

                                            </Grid>
                                            <Grid item xs={6}>
                                                <Typography><b>Doktor Adı Soyadı:</b> {(this.state.tetkik !== null) ? this.state.tetkik.doctorName + " " + this.state.tetkik.doctorSurname : ""}</Typography>
                                                <Typography style={{ marginTop: "10px" }}><b>Doktor Telefon Numarası:</b> {(this.state.tetkik !== null) ? this.state.tetkik.doctorMobilePhone : ""}</Typography>
                                            </Grid>

                                        </Grid>
                                        <Grid container spacing={2} style={{ marginTop: "10px" }}>

                                            <Grid item xs={6}>
                                                <Typography style={{ fontWeight: "bold" }}>Tetkik İşlemleri</Typography>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <Typography style={{ fontWeight: "bold" }}>Tetkik Sonuçları Yükleme</Typography>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <Grid container spacing={2} justifyContent="left" alignItems="left">
                                                    <Grid item lg={5} xl={5} xs={12}>{this.customList('Talep Edilen Tetkikler', this.state.left)}</Grid>
                                                    <Grid item lg={2} xl={2} xs={12}>
                                                        <Grid container direction="column" alignItems="center">
                                                            <Button
                                                                variant="outlined"
                                                                size="small"
                                                                onClick={this.handleCheckedRight}
                                                                disabled={(this.state.tetkik !== null) ? (this.state.tetkik.status === 3) ? true : false : false}
                                                                aria-label="move selected right"
                                                            >
                                                                &gt;
                                                            </Button>
                                                            <Button
                                                                variant="outlined"
                                                                size="small"
                                                                onClick={this.handleCheckedLeft}
                                                                disabled={(this.state.tetkik !== null) ? (this.state.tetkik.status === 3) ? true : false : false}
                                                                aria-label="move selected left"
                                                            >
                                                                &lt;
                                                            </Button>
                                                        </Grid>
                                                    </Grid>
                                                    <Grid item lg={5} xl={5} xs={12}>{this.customList('Tetkik Sonucu Yüklenecekler', this.state.right)}</Grid>
                                                </Grid>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <Upload appointmentId={Number(this.props.match.params.id)} examination={this.state.right[0]}
                                                    onUploaded={this.onUploadFinished} disabled={(this.state.tetkik !== null) ? (this.state.tetkik.status === 3) ? true : false : false} />
                                                <Typography style={{ fontWeight: "bold", marginTop: "15px" }}>Tetkik Talebi Fiyatı (TL):</Typography>
                                                <TextField value={this.state.talepGerceklesenFiyat} style={{ width: "100%" }}
                                                    onChange={this.onChangeActualPrizeTextField}
                                                /*disabled={(this.state.tetkik !== null) ? (this.state.tetkik.status === 3) ? true : false : false}*/ />

                                                <Typography style={{ fontWeight: "bold", marginTop: "15px" }}>Tamamlanan Tetkik Bilgileri</Typography>
                                                {
                                                    this.state.uploadTetkikItems.map((tetkik, idx) => (
                                                        <Typography key={idx} >{tetkik.productName}</Typography>
                                                    ))
                                                }



                                            </Grid>
                                        </Grid>

                                    </CardContent>
                                    <CardActions >
                                        {this.state.tetkikItems?.length > 0 && 
                                        <ReactToPrint
                                            trigger={() => {
                                                return <Button variant='contained' color='primary'
                                                style={{ marginInlineEnd: "auto" }}>
                                                <PrintIcon/>TETKİK TALEPLERİNİ YAZDIR
                                            </Button>;
                                            }}
                                            content={() => this.componentRef}
                                        />
                                        }
                                        {this.state.tetkikItems?.length > 0 &&
                                            <span style={{display: 'none'}}>
                                                <div  ref={el => (this.componentRef = el)} style={{margin: '10px'}}>
                                                    {this.printTetkikRequest()}
                                                </div>
                                            </span>}
                                        <Button variant='contained' color='primary'
                                            onClick={this.onClickTetkikIslemKapat} style={{ marginInlineStart: "auto" }}
                                            /*disabled={(this.state.tetkik !== null) ? (this.state.tetkik.status === 3) ? true : false : false}*/>
                                            TETKİK TALEBİNİ KAPAT
                                        </Button>
                                    </CardActions>
                                </Card>
                            </Grid>
                        </Grid>
                    </Container>
                </Box>
                <MapissoAlert />
            </div>
        )
    }
}

export default inject("store")(observer(TetkikTalepBilgiPage));
