import axios from "axios";

const postUserLogout = async (baseUrl, token, userId) => {

    const config = {
        headers: { Authorization: `Bearer ${token}` }
    };

    let postResponse = null

    await axios.post(baseUrl + `/lab/Logout/`+ userId, {}, config)
        .then((res) => {
            console.log("res.data: ", res.data)
            postResponse = {
                status: res.status,
                data: res.data.data
            }
        }).catch((error) => {
            if (error.response !== undefined) {
                postResponse = {
                    status: error.response.status,
                    data: error.response.status
                }
            }
        });
    return postResponse
}

export default postUserLogout;