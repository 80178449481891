import React, { Component } from 'react'
import { observer, inject } from "mobx-react"

import { Button, Card, CardActions, CardContent, CardHeader, TextField, Link } from '@mui/material';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

import { LeftMenu } from '../../components/common/LeftMenu';

import { Box, Container, Grid } from '@mui/material';
import dayjs from 'dayjs';
import PageviewIcon from '@mui/icons-material/Pageview';

import getLabFirmTetkikList from '../../utils/api/tetkik/GetLabFirmTetkikList'
import getLabFirmTetkikSearch from '../../utils/api/tetkik/GetLabFirmTetkikSearch'

class TetkikListesiPage extends Component {

    constructor(props) {
        super(props)
        this.state = {
            requestList: [],
            backupRequestList: [],
            tckno: ""
        }
    }

    componentDidMount = async () => {
        if (!this.props.store.isLogin) {
            this.props.store.setLoadingBarStatus(true)
            this.props.history.push("/lab/kullanici-girisi");
        } else {
            this.props.store.setLoadingBarStatus(false)
            let getLabFirmTetkikListResponse = await getLabFirmTetkikList(this.props.store.baseUrl, this.props.store.token, this.props.store.user[0].id, this.props.store.firm[0].firmId)
            console.log("getLabFirmTetkikListResponse: ", getLabFirmTetkikListResponse)
            if (getLabFirmTetkikListResponse !== null && getLabFirmTetkikListResponse !== undefined) {
                if (getLabFirmTetkikListResponse.status === 200) {
                    if (getLabFirmTetkikListResponse.data !== null) {
                        let tetkikTalepListesi = getLabFirmTetkikListResponse.data
                        //tetkikTalepListesi = tetkikTalepListesi.filter(function (e) { return e.status !== 3 })
                        this.setState({
                            requestList: tetkikTalepListesi,
                            backupRequestList: tetkikTalepListesi
                        })
                    }else{
                        this.setState({
                            requestList: [],
                            backupRequestList: []
                        })
                    }


                }
            }
            this.props.store.setLoadingBarStatus(true)
        }
    }

    onClickTcknoSearch = async () => {
        this.props.store.setLoadingBarStatus(false)

        if (this.state.tckno.length === 11) {
            let searchResponse = await getLabFirmTetkikSearch(this.props.store.baseUrl, this.props.store.token, this.props.store.user[0].id, this.props.store.firm[0].firmId,
                this.state.tckno)
            if (searchResponse !== null && searchResponse !== undefined) {
                if (searchResponse.status === 200) {

                    if (searchResponse.data !== null) {

                        let tetkikTalepListesi = searchResponse.data
                        //tetkikTalepListesi = tetkikTalepListesi.filter(function (e) { return e.status !== 3 })

                        this.setState({
                            requestList: tetkikTalepListesi
                        })
                    } else {
                        this.setState({
                            requestList: []
                        })
                    }

                } else {
                    this.setState({
                        requestList: []
                    })
                }
            }

        } else {
            this.props.store.token("T.C. Kimlik Numarasının tamamını girmelisiniz", true, "error")
        }

        this.props.store.setLoadingBarStatus(true)
    }

    onChangeTextInput = (event) => {
        this.setState({
            tckno: event.target.value
        })
    }

    onClickFilterClear = () => {
        this.setState({
            tckno: "",
            requestList: this.state.backupRequestList
        })
    }

    render() {

        return (
            <div className="container mps-container">
                <LeftMenu activePage={"Tetkik Talepleri"} />
                <Box component="main" sx={{
                    flexGrow: 1,
                    py: 8
                }} style={{ width: "calc(100% - 250px)", marginLeft: "250px", marginTop: "20px" }} >
                    <Container maxWidth={false}>
                        <Grid container spacing={3}>
                            <Grid item lg={12} md={12} xl={12} xs={12}>
                                <Card style={{ height: '100%' }}>
                                    <CardHeader title="Gelen Tetkik Talepleri" style={{ paddingBottom: "10px" }} />
                                    <CardContent style={{ paddingTop: "0", height: 'calc(100vh - 250px)' }}>
                                        <TextField label="Hasta T.C. Kimlik No" value={this.state.tckno} onChange={this.onChangeTextInput}
                                            style={{ marginBottom: "15px", width: "350px" }} size="small"></TextField>
                                        <Button variant="contained" color="primary" style={{ marginLeft: "10px" }}
                                            onClick={this.onClickTcknoSearch}>Talep Ara</Button>
                                        <Button variant="contained" color="error" style={{ marginLeft: "10px" }}
                                            onClick={this.onClickFilterClear}>Filtre Temizle</Button>
                                        <TableContainer style={{ height: '98%' }}>
                                            <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                                <TableHead>
                                                    <TableRow>
                                                        <TableCell align="left">DOKTOR ADI SOYADI</TableCell>
                                                        <TableCell align="left">HASTA ADI SOYADI</TableCell>
                                                        <TableCell align="left">HASTA TELEFON NUMARASI</TableCell>
                                                        <TableCell align="left">TALEP TARİHİ</TableCell>
                                                        <TableCell align="left">TALEP DURUMU</TableCell>
                                                        <TableCell align="left">İŞLEMLER</TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {this.state.requestList.map((request, idx) => (
                                                        <TableRow key={idx}>
                                                            <TableCell align="left">{"Doktor " + request.doctorName + " " + request.doctorSurname}</TableCell>
                                                            <TableCell align="left">{request.patientName + " " + request.patientSurname}</TableCell>
                                                            <TableCell align="left">{request.patientMobilePhone}</TableCell>
                                                            <TableCell align="left">{dayjs(request.appointmentDate).format("DD.MM.YYYY HH:mm")}</TableCell>
                                                            <TableCell align="left">{request.statusName}</TableCell>
                                                            <TableCell align="left">
                                                                <Button variant='outlined' component={Link} href={"/lab/talep/" + request.appointmentId}>
                                                                    <PageviewIcon style={{ marginRight: "10px" }} /> Talep Görüntüle
                                                                </Button>
                                                            </TableCell>
                                                        </TableRow>
                                                    ))}

                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                    </CardContent>
                                    <CardActions >
                                    </CardActions>
                                </Card>
                            </Grid>
                        </Grid>
                    </Container>
                </Box>
            </div>
        )
    }
}

export default inject("store")(observer(TetkikListesiPage));
