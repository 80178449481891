import { types } from "mobx-state-tree"

export const User = types
    .model({
        id: types.optional(types.number, 0),
        name: types.optional(types.string,""),
        surname: types.optional(types.string, ""),
        email: types.optional(types.string, ""),
        tckno: types.optional(types.number, 0),
        mobilePhone: types.optional(types.number, 0),
        token: types.optional(types.string, ""),
        firmId: types.optional(types.number, 0),
        firmName: types.optional(types.string, "")
    }).actions(self => ({

    }))