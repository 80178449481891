import * as React from 'react';
import { observer, inject } from "mobx-react"

import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { Fab, LinearProgress, Link, Tooltip } from '@mui/material';

import MapissoAlert from './MapissoAlert'

import postUserLogout from '../../utils/api/tetkik/UserLogOut'

import {FaRegUserCircle, FaRegUser} from 'react-icons/fa'
import { FiInbox, FiLogOut } from 'react-icons/fi'
import { IoIosArrowDown } from 'react-icons/io'
import DocumentScannerIcon from '@mui/icons-material/DocumentScanner';
import SettingsIcon from '@mui/icons-material/Settings';

import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';

const drawerWidth = 240;

export const LeftMenu = inject("store")(observer((props) => {

    const [profileMenuDisplay, setProfileMenuDisplay] = React.useState(false)
    const [profileMenuTarget, setProfileMenuTarget] = React.useState(null)

    const pages = [
        {
            name: "Ana Sayfa",
            url: "/lab",
            icon: <FiInbox style={{ fontSize: "20px", color: "#1ABC9C" }} />
        },
        {
            name: "Tetkik Talepleri",
            url: "/lab/talep-listesi",
            icon: <DocumentScannerIcon style={{ fontSize: "20px", color: "#1ABC9C" }} />
        },
        {
            name: "Tetkik İşlemleri",
            url: "/lab/tetkik-islemler",
            icon: <SettingsIcon style={{ fontSize: "20px", color: "#1ABC9C" }} />
        },
        {
            name: "Muhasebe",
            url: "/lab/muhasebe",
            icon: <AccountBalanceWalletIcon style={{ fontSize: "20px", color: "#1ABC9C" }} />
        }
    ]

    const onHandleProfileMenuOpen = (event) => {
        setProfileMenuDisplay(true)
        setProfileMenuTarget(event.currentTarget)
    }

    const onClickProfileMenuClose = () => {
        setProfileMenuDisplay(false)
        setProfileMenuTarget(null)
    }

    const onLogout = async() => {
        await postUserLogout(props.store.baseUrl, props.store.token, props.store.user[0].id)
        //console.log("userlogout: ", userLogoutResponse)
        props.store.setUserLogout()
        window.location.reload()
    }

    return (
        <>
            <Box sx={{ display: 'flex' }} >
                <AppBar position="fixed" sx={{ width: "100%" }} >
                    <Toolbar key="topMenu">
                        <a href="/lab">
                            <img src='/assets/images/wholemedic-logo.png' alt="wholemedics" style={{ width: "200px", marginRight: "50px" }} />
                        </a>
                        <Typography color="secondary" style={{ fontWeight: "bold" }}>
                            Tetkik Paneli
                        </Typography>
                        <Tooltip title="Kullanıcı Menüsü" placement='bottom'>
                            <Fab variant="extended" className='profile-menu' color="primary" onClick={(event) => onHandleProfileMenuOpen(event)}>
                                <FaRegUser style={{ fontSize: "25px", marginRight: "5px" }} />
                                    {props.store.firm[0]?.firmName}
                                <IoIosArrowDown style={{ fontSize: "15px", marginLeft: "5px" }} />
                            </Fab>
                        </Tooltip>
                        <Menu id="profile-menu"
                            anchorEl={profileMenuTarget}
                            open={profileMenuDisplay}
                            onClose={onClickProfileMenuClose} style={{ marginTop: "15px" }}>
                            <MenuItem href="/lab/profilim" component={Link} style={{ width: "175px", textAlign: "center", padding: "13px" }}>
                                <FaRegUserCircle style={{ fontSize: "20px", marginRight: "5px" }} /> <Typography variant="body1" style={{ fontWeight: "400" }}>Profilim</Typography>
                            </MenuItem>
                            <MenuItem onClick={() => onLogout()} style={{ width: "175px", textAlign: "center", padding: "13px" }}>
                                <FiLogOut style={{ color: "#D14343", fontSize: "20px", marginRight: "5px" }} /><Typography variant="body1" style={{ fontWeight: "600", color: "#D14343" }}>Çıkış Yap</Typography>
                            </MenuItem>
                        </Menu>
                    </Toolbar>
                    <div style={{ position: "absolute", height: "5px", width: "100%", zIndex: "5000" }}>
                        <LinearProgress style={{ display: (props.store.loadingBarStatus) ? "none" : "block" }} color="primary" />
                    </div>
                </AppBar>
                <Drawer sx={{
                    width: drawerWidth,
                    flexShrink: 0,
                    '& .MuiDrawer-paper': {
                        width: drawerWidth,
                        boxSizing: 'border-box',
                    },
                }}
                    variant="permanent"
                    anchor="left">
                    <Toolbar key="leftMenu">

                    </Toolbar>
                    <List  disablePadding>
                        {pages.map((page, index) => (
                            (props.activePage === page.name) ?
                                <Link key={index} href={page.url}>
                                    <ListItem disableGutters disablePadding style={{ background: "rgba(0, 0, 0, 0.1)" }}>
                                        <ListItemButton>
                                            <ListItemIcon>
                                                {page.icon}
                                            </ListItemIcon>
                                            <ListItemText primary={page.name} />
                                        </ListItemButton>
                                    </ListItem>
                                </Link> :
                                <Link key={index} href={page.url}>
                                    <ListItem disableGutters disablePadding>
                                        <ListItemButton>
                                            <ListItemIcon>
                                                {page.icon}
                                            </ListItemIcon>
                                            <ListItemText primary={page.name} />
                                        </ListItemButton>
                                    </ListItem>
                                </Link>
                        ))}
                    </List>
                </Drawer>
                <MapissoAlert />
            </Box>
        </>
    );
}))