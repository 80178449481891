import axios from "axios";

const deleteLabProduct = async (baseUrl, token, userId, productId) => {

    const config = {
        headers: { Authorization: `Bearer ${token}` }
    };

    let deleteResponse = null

    await axios.delete(baseUrl + "/lab/" + userId + "/product/?productid=" + productId, config)
        .then((res) => {
            deleteResponse = {
                status: res.data.status,
                result: res.data.data
            }
        }).catch((error) => {
            if (error.response !== undefined) {
                deleteResponse = {
                    status: error.response.status,
                    result: error.response.status
                }
            }
        });
    return deleteResponse
}

export default deleteLabProduct;