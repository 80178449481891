import React, { Component } from 'react'
import { observer, inject } from "mobx-react"

import {
    Card, Typography, CardContent, List, ListItem, ListItemButton,
    ListItemAvatar, ListItemText, Avatar, Box, Container, Grid, Button,
    Dialog, DialogTitle, DialogContent, DialogActions, TextField,
    FormControl, InputLabel, Select, MenuItem, IconButton, Tooltip
} from '@mui/material';

import { SketchPicker } from 'react-color';

import DeleteIcon from '@mui/icons-material/Delete'
import FolderIcon from '@mui/icons-material/Folder';
import AddIcon from '@mui/icons-material/Add'
import UpdateIcon from '@mui/icons-material/Update';
import { ImLab } from 'react-icons/im'

import getLabFirmCategoryList from '../../utils/api/tetkik/GetLabFirmCategoryList'
import getLabProductListCategoryById from '../../utils/api/tetkik/GetLabProductListCategoryById'
import putLabCategory from '../../utils/api/tetkik/PutLabCategory'
import putLabProduct from '../../utils/api/tetkik/PutLabProduct'
import deleteLabCategory from '../../utils/api/tetkik/DeleteLabCategory'
import deleteLabProduct from '../../utils/api/tetkik/DeleteLabProduct'

import { LeftMenu } from '../../components/common/LeftMenu';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

class DashboardPage extends Component {

    constructor(props) {
        super(props)
        this.state = {
            examinationCategoryList: [],
            examinationProductList: [],
            selectedProductList: [],
            kategoriEkleMenuDisplay: false,
            urunEkleMenuDisplay: false,
            categoryCode: "",
            categoryName: "",
            productCode: "",
            productName: "",
            productPrice: 0,
            selectedKategoriId: 0,
            selectedCategoryName: "",
            selectedCategoryColor: "",
            selectedCategoryColorCode: "",
            updateCategoriMenuDisplay: false,
            updateProductMenuDislay: false,
            selectedProductId: 0
        }
    }

   

    componentDidMount = async () => {
        if (!this.props.store.isLogin) {
            this.props.store.setLoadingBarStatus(true)
            this.props.history.push("/lab/kullanici-girisi");
        } else {

            await this.getCategoryList()
        }
    }

    getCategoryList = async () => {
        this.props.store.setLoadingBarStatus(false);

        let labFirmCategoryResponse = await getLabFirmCategoryList(this.props.store.baseUrl, this.props.store.token, this.props.store.user[0].id, this.props.store.firm[0].firmId)
        if (labFirmCategoryResponse !== null && labFirmCategoryResponse !== undefined) {
            if (labFirmCategoryResponse.status === 200) {
                //console.log("labFirmCategoryResponse: ", labFirmCategoryResponse)
                this.setState({
                    examinationCategoryList: labFirmCategoryResponse.data
                })
            } else if (labFirmCategoryResponse.status === 401) {
                this.props.store.setUserLogout()
                window.location.reload()
            }
        }

        this.props.store.setLoadingBarStatus(true);
    }

    onClickExaminationCategoryButton = async (categoryId) => {
        this.props.store.setLoadingBarStatus(false)
        let labFirmProductResponse = await getLabProductListCategoryById(this.props.store.baseUrl, this.props.store.token,
            this.props.store.user[0].id, this.props.store.firm[0].firmId, categoryId)

        for (let index = 0; index < this.state.examinationCategoryList.length; index++) {
            const examinationCategory = this.state.examinationCategoryList[index];
            if (examinationCategory.categoryId === categoryId) {
                this.setState({
                    selectedCategoryName: examinationCategory.categoryName,
                    selectedCategoryColor: examinationCategory.color
                })
            }
        }

        if (labFirmProductResponse !== null && labFirmProductResponse !== undefined) {
            if (labFirmProductResponse.status === 200) {
                console.log("labFirmProductResponse.data: ", labFirmProductResponse.data)
                if (labFirmProductResponse.data !== null) {
                    this.setState({
                        examinationProductList: labFirmProductResponse.data
                    })
                } else {
                    this.setState({
                        examinationProductList: []
                    })
                }
            }
        }
        this.props.store.setLoadingBarStatus(true)
    }

    checkProductSelectedAlready = (product) => {
        let productExist = false
        for (let i = 0; i < this.state.selectedProductList.length; i++) {
            const tempSelectedProduct = this.state.selectedProductList[i];
            if (tempSelectedProduct.productId === product.productId) {
                productExist = true
            }
        }

        return productExist;
    }

    onClickSelectProduct = (product) => {

        let productExist = this.checkProductSelectedAlready(product)

        if (productExist) {
            this.setState({
                selectedProductList: this.state.selectedProductList.filter(a =>
                    a.productId !== product.productId
                )
            })
        } else {
            product.color = this.state.selectedCategoryColor
            this.setState({
                selectedProductList: [...this.state.selectedProductList, product]
            })
        }
    }

    onClickDeleteSelectedProduct = (product) => {
        this.setState({
            selectedProductList: this.state.selectedProductList.filter(a =>
                a.productId !== product.productId
            )
        })
    }

    handleClickOpenKategoriEkleMenu = () => {
        this.setState({
            kategoriEkleMenuDisplay: true
        })
    }

    onChangeKategoriCodeTextField = (event) => {
        this.setState({
            categoryCode: event.target.value
        })
    }

    onChangeKategoriAdiTextField = (event) => {
        this.setState({
            categoryName: event.target.value
        })
    }

    handleClickCloseKategoriEkleMenu = () => {
        this.setState({
            kategoriEkleMenuDisplay: false
        })
    }

    onClickSaveKategori = async () => {
        this.props.store.setLoadingBarStatus(false)
        let kategori = {
            categoryCode: this.state.categoryCode,
            categoryName: this.state.categoryName,
            firmId: this.props.store.firm[0].firmId,
            color: this.state.selectedCategoryColorCode
        }

        let putLabCategoryResponse = await putLabCategory(this.props.store.baseUrl, this.props.store.token, this.props.store.user[0].id, kategori)
        if (putLabCategoryResponse !== null && putLabCategoryResponse !== undefined) {
            if (putLabCategoryResponse.status === 200) {
                this.props.store.setMessage("Kategori başarıyla oluşturuldu", true, "success")
                await this.getCategoryList()
            } else {
                this.props.store.setMessage("Kategori oluşturulurken hata oluştu ", true, "error")
            }
        } else {
            this.props.store.setMessage("Kategori oluşturulurken hata oluştu ", true, "error")
        }
        this.props.store.setLoadingBarStatus(true)

    }

    onChangeKategoriChange = (event) => {
        this.setState({
            selectedKategoriId: event.target.value
        })
    }

    onClickOpenProductMenuEkle = () => {
        this.setState({
            urunEkleMenuDisplay: true
        })
    }

    handleClickCloseUrunEkleMenu = () => {
        this.setState({
            urunEkleMenuDisplay: false
        })
    }

    onChangeProductCodeTextField = (event) => {
        this.setState({
            productCode: event.target.value
        })
    }

    onChangeProductNameTextField = (event) => {
        this.setState({
            productName: event.target.value
        })
    }

    onChangeProductPriceTextField = (event) => {
        this.setState({
            productPrice: Number(event.target.value)
        })
    }

    onClickSaveProduct = async () => {
        this.props.store.setLoadingBarStatus(false)

        let product = {
            categoryId: this.state.selectedKategoriId,
            firmId: this.props.store.firm[0].firmId,
            productCode: this.state.productCode,
            productName: this.state.productName,
            isActive: true,
            price: this.state.productPrice
        }

        let putProductResponse = await putLabProduct(this.props.store.baseUrl, this.props.store.token, this.props.store.user[0].id, product)
        if (putProductResponse !== null && putProductResponse !== undefined) {
            if (putProductResponse.status === 200) {
                this.props.store.setMessage("Ürün ekleme işlemi başarılı", true, "success")
            } else {
                this.props.store.setMessage("Ürün eklerken hata oluştu", true, "error")
            }
        } else {
            this.props.store.setMessage("Ürün eklerken hata oluştu", true, "error")
        }

        this.props.store.setLoadingBarStatus(true)
    }

    handleChangeColorSelect = (color) => {
        this.setState({ selectedCategoryColorCode: color.hex });
    }

    onClickUpdateTetkikKategoriButton = (category) => {
        this.setState({
            categoryCode: category.categoryCode,
            categoryName: category.categoryName,
            selectedCategoryColorCode: category.color,
            selectedKategoriId: category.categoryId,
            updateCategoriMenuDisplay: true
        })
    }

    onClickUpdateTetkikProductButton = (product) => {
        this.setState({
            productCode: product.productCode,
            productName: product.productName,
            productPrice: product.price,
            selectedKategoriId: product.categoryId,
            selectedProductId: product.productId,
            updateProductMenuDislay: true
        })
    }

    onClickUpdateKategori = async() => {
        this.props.store.setLoadingBarStatus(false)
        let kategori = {
            categoryCode: this.state.categoryCode,
            categoryName: this.state.categoryName,
            firmId: this.props.store.firm[0].firmId,
            color: this.state.selectedCategoryColorCode,
            categoryId: this.state.selectedKategoriId
        }

        let putLabCategoryResponse = await putLabCategory(this.props.store.baseUrl, this.props.store.token, this.props.store.user[0].id, kategori)
        if (putLabCategoryResponse !== null && putLabCategoryResponse !== undefined) {
            if (putLabCategoryResponse.status === 200) {
                this.props.store.setMessage("Kategori başarıyla oluşturuldu", true, "success")
                await this.getCategoryList()
            } else {
                this.props.store.setMessage("Kategori güncellenirken hata oluştu ", true, "error")
            }
        } else {
            this.props.store.setMessage("Kategori güncellenirken hata oluştu ", true, "error")
        }
        this.props.store.setLoadingBarStatus(true)
    }

    handleClickCloseUpdateKategoriEkleMenu = () => {
        this.setState({
            updateCategoriMenuDisplay: false
        })
    }

    onClickUpdateProduct = async() => {
        this.props.store.setLoadingBarStatus(false)

        let product = {
            categoryId: this.state.selectedKategoriId,
            firmId: this.props.store.firm[0].firmId,
            productCode: this.state.productCode,
            productName: this.state.productName,
            isActive: true,
            price: this.state.productPrice,
            productId: this.state.selectedProductId
        }

        let putProductResponse = await putLabProduct(this.props.store.baseUrl, this.props.store.token, this.props.store.user[0].id, product)
        if (putProductResponse !== null && putProductResponse !== undefined) {
            if (putProductResponse.status === 200) {
                this.props.store.setMessage("Ürün güncelleme işlemi başarılı", true, "success")
            } else {
                this.props.store.setMessage("Ürün güncellenirken hata oluştu", true, "error")
            }
        } else {
            this.props.store.setMessage("Ürün güncellenirken hata oluştu", true, "error")
        }

        this.props.store.setLoadingBarStatus(true)
    }

    handleClickCloseUpdateProductMenu = () => {
        this.setState({
            updateProductMenuDislay: false
        })
    }

    onClickDeleteCategoryDelete = async(categoryId) => {
        this.props.store.setLoadingBarStatus(false)

        let deleteCategoryResponse = await deleteLabCategory(this.props.store.baseUrl, this.props.store.token,
            this.props.store.user[0].id, categoryId)
        if(deleteCategoryResponse !== null && deleteCategoryResponse !== undefined){
            if(deleteCategoryResponse.status === 200){
                this.props.store.setMessage("Kategori başarıyla silindi", true, "success")
                this.getCategoryList()
            }else{
                this.props.store.setMessage("Kategori silinirken hata oluştu", true, "error")
            }
        }

        this.props.store.setLoadingBarStatus(true)
    }

    onClickDeleteProductDelete = async(productId, categoryId) => {
        this.props.store.setLoadingBarStatus(false)

        let deleteProductResponse = await deleteLabProduct(this.props.store.baseUrl, this.props.store.token,
            this.props.store.user[0].id, productId)
        if(deleteProductResponse !== null && deleteProductResponse !== undefined){
            if(deleteProductResponse.status === 200){
                this.props.store.setMessage("Ürün başarıyla silindi", true, "success")

                this.onClickExaminationCategoryButton(categoryId)
            }else{
                this.props.store.setMessage("Ürün silinirken hata oluştu", true, "error")
            }
        }

        this.props.store.setLoadingBarStatus(true)
    }

    render() {

        return (
            <div className="container mps-container">
                <LeftMenu activePage={"Tetkik İşlemleri"} />
                <Box component="main" sx={{
                    flexGrow: 1,
                    py: 8
                }} style={{ width: "calc(100% - 250px)", marginLeft: "250px", marginTop: "20px" }} >
                    <Container maxWidth={false}>
                        <Grid container spacing={3}>
                            <Grid item lg={12} md={12} xl={12} xs={12}>
                                <Card style={{ height: '100%' }}>
                                    <CardContent style={{ height: 'calc(100vh - 150px)' }}>
                                        <Grid container spacing={3}>
                                            <Grid item lg={8} md={8} xl={8} xs={8}>
                                                <Typography variant='h6'>Tetkik İşlemleri</Typography>
                                            </Grid>
                                            <Grid item lg={4} md={4} xl={4} xs={4}>
                                                <Button style={{ float: "right", margin: "10px" }} color="primary"
                                                    variant='contained' onClick={this.onClickOpenProductMenuEkle}>
                                                    <AddIcon /> Ürün Ekle</Button>
                                                <Button style={{ float: "right", margin: "10px" }} color="primary"
                                                    variant='contained' onClick={this.handleClickOpenKategoriEkleMenu}>
                                                    <AddIcon /> Kategori Ekle</Button>
                                            </Grid>
                                        </Grid>
                                        <Typography>Bilgi Notu: Tetkik ürün fiyatlarında güncelleme yapmanız durumunda ilgili fiyat güncellemesi Wholemedics onayına gönderilir. <br/> Fiyat onaylama işlemi gerçekleşene kadar ilgili tetkik ürünü listeden kaldırılır.</Typography>
                                        <Grid container spacing={0} style={{
                                            width: "100%", marginTop: "15px", border: "1px solid #e0e0e0", height: "calc(100vh - 300px)",
                                            maxHeight: "calc(100vh - 300px)", overflowY: "auto", borderRadius: "10px"
                                        }}>
                                            <Grid item xs={5} style={{
                                                padding: "20px", borderRight: "1px solid #e0e0e0"}}>
                                                < Typography variant='body2' style={{ fontWeight: "bold" }}>
                                                    Tetkik Kategori Listesi
                                                </Typography>
                                                <List sx={{
                                                    width: '100%',
                                                    bgcolor: 'background.paper',
                                                    position: 'relative',
                                                    overflow: 'auto',
                                                    maxHeight: 'calc(100vh - 375px)',
                                                    '& ul': { padding: 0 },
                                                }}>
                                                    {
                                                        this.state.examinationCategoryList.map((category, idx) => (
                                                            <ListItem key={idx} disablePadding secondaryAction={
                                                                <>
                                                                    <Tooltip title="Kategori Güncelle">
                                                                        <IconButton edge="end" aria-label="delete" onClick={() => this.onClickUpdateTetkikKategoriButton(category)}>
                                                                            <UpdateIcon />
                                                                        </IconButton>
                                                                    </Tooltip>

                                                                    <Tooltip title="Kategori Sil">
                                                                        <IconButton edge="end" aria-label="delete" onClick={() => this.onClickDeleteCategoryDelete(category.categoryId)}>
                                                                            <DeleteIcon />
                                                                        </IconButton>
                                                                    </Tooltip>
                                                                </>
                                                            }>
                                                                <ListItemButton onClick={() => this.onClickExaminationCategoryButton(category.categoryId)}>
                                                                    <ListItemAvatar>
                                                                        <Avatar style={{ background: category.color }}>
                                                                            <ImLab />
                                                                        </Avatar>
                                                                    </ListItemAvatar>
                                                                    <Typography style={{ fontSize: "13px" }}>{category.categoryName}</Typography>
                                                                </ListItemButton>
                                                            </ListItem>
                                                        ))
                                                    }
                                                </List>
                                            </Grid>
                                            <Grid item xs={7} style={{
                                                padding: "20px", borderRight: "1px solid #e0e0e0"}}>
                                                <Typography variant='body2' style={{ fontWeight: "bold" }}>
                                                    Seçtiğinin Kategoriye Bağlı Tetkik Listesi
                                                </Typography>
                                                <List dense={true} sx={{
                                                    width: '100%',
                                                    bgcolor: 'background.paper',
                                                    position: 'relative',
                                                    overflow: 'auto',
                                                    maxHeight: 'calc(100vh - 375px)',
                                                    '& ul': { padding: 0 },
                                                }}>
                                                    {
                                                        (this.state.examinationProductList.length !== 0) ?
                                                            this.state.examinationProductList.map((product, idx) => (
                                                                <ListItem key={idx}secondaryAction={
                                                                    <>
                                                                        <Tooltip title="Ürün Güncelle">
                                                                            <IconButton edge="end" aria-label="delete" onClick={() => this.onClickUpdateTetkikProductButton(product)}>
                                                                                <UpdateIcon />
                                                                            </IconButton>
                                                                        </Tooltip>
    
                                                                        <Tooltip title="Ürün Sil">
                                                                            <IconButton edge="end" aria-label="delete" onClick={() => this.onClickDeleteProductDelete(product.productId, product.categoryId)}>
                                                                                <DeleteIcon />
                                                                            </IconButton>
                                                                        </Tooltip>
                                                                    </>
                                                                }>
                                                                    <ListItemAvatar>
                                                                        <Avatar style={{ background: this.state.selectedCategoryColor }}>
                                                                            <ImLab />
                                                                        </Avatar>
                                                                    </ListItemAvatar>
                                                                    <ListItemText
                                                                        primary={product.productName}
                                                                        secondary={product.price + " TL"}
                                                                    />
                                                                </ListItem>
                                                            ))
                                                            :
                                                            <ListItem>
                                                                <ListItemAvatar>
                                                                    <Avatar>
                                                                        <FolderIcon />
                                                                    </Avatar>
                                                                </ListItemAvatar>
                                                                <ListItemText
                                                                    primary="Henüz kategori seçmediniz ya da seçtiğiniz kategoride tanımlı tetkik bulunmamaktadır."
                                                                />
                                                            </ListItem>
                                                    }
                                                </List>
                                            </Grid>
                                        </Grid>
                                    </CardContent>
                                </Card>
                            </Grid>
                        </Grid>
                    </Container >
                </Box >
                <Dialog
                    fullWidth={true}
                    maxWidth={"sm"}
                    open={this.state.kategoriEkleMenuDisplay}
                    onClose={this.handleClickOpenKategoriEkleMenu}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description" >
                    <DialogTitle id="alert-dialog-title">
                        {"Kategori Ekle"}
                    </DialogTitle>
                    <DialogContent style={{ padding: "30px", paddingTop: "0" }}>
                        <Grid container spacing={3}>
                            <Grid item xs={6}>
                                <Typography>Kategori Kodu:</Typography>
                                <TextField name="kodu" value={this.state.categoryCode} label="Kategori Kodu*" style={{ width: "100%", marginTop: "10px" }}
                                    onChange={this.onChangeKategoriCodeTextField} />
                                <Typography style={{ marginTop: "10px" }}>Kategori İsmi:</Typography>
                                <TextField name="name" value={this.state.categoryName} label="Kategori Adı*" style={{ width: "100%", marginTop: "10px" }}
                                    onChange={this.onChangeKategoriAdiTextField} />
                            </Grid>
                            <Grid item xs={6}>
                                <Typography>Kategori Rengi:</Typography>
                                <SketchPicker
                                    color={this.state.selectedCategoryColorCode}
                                    onChangeComplete={this.handleChangeColorSelect}
                                />
                                <Typography>Seçtiğiniz renk kodu: {this.state.selectedCategoryColorCode}</Typography>
                            </Grid>
                        </Grid>



                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.handleClickCloseKategoriEkleMenu}>Kapat</Button>
                        <Button color="primary" variant='contained' onClick={this.onClickSaveKategori} >
                            Kategori Ekle
                        </Button>
                    </DialogActions>
                </Dialog>
                <Dialog
                    fullWidth={true}
                    maxWidth={"sm"}
                    open={this.state.updateCategoriMenuDisplay}
                    onClose={this.handleClickOpenKategoriEkleMenu}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description" >
                    <DialogTitle id="alert-dialog-title">
                        {"Kategori Güncelle"}
                    </DialogTitle>
                    <DialogContent style={{ padding: "30px", paddingTop: "0" }}>
                        <Grid container spacing={3}>
                            <Grid item xs={6}>
                                <Typography>Kategori Kodu:</Typography>
                                <TextField name="kodu" value={this.state.categoryCode} label="Kategori Kodu*" style={{ width: "100%", marginTop: "10px" }}
                                    onChange={this.onChangeKategoriCodeTextField} />
                                <Typography style={{ marginTop: "10px" }}>Kategori İsmi:</Typography>
                                <TextField name="name" value={this.state.categoryName} label="Kategori Adı*" style={{ width: "100%", marginTop: "10px" }}
                                    onChange={this.onChangeKategoriAdiTextField} />
                            </Grid>
                            <Grid item xs={6}>
                                <Typography>Kategori Rengi:</Typography>
                                <SketchPicker
                                    color={this.state.selectedCategoryColorCode}
                                    onChangeComplete={this.handleChangeColorSelect}
                                />
                                <Typography>Seçtiğiniz renk kodu: {this.state.selectedCategoryColorCode}</Typography>
                            </Grid>
                        </Grid>

                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.handleClickCloseUpdateKategoriEkleMenu}>Kapat</Button>
                        <Button color="primary" variant='contained' onClick={this.onClickUpdateKategori} >
                            Kategori Güncelle
                        </Button>
                    </DialogActions>
                </Dialog>
                <Dialog
                    fullWidth={true}
                    maxWidth={"sm"}
                    open={this.state.urunEkleMenuDisplay}
                    onClose={this.handleClickCloseUrunEkleMenu}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description" >
                    <DialogTitle id="alert-dialog-title">
                        {"Ürün Ekle"}
                    </DialogTitle>
                    <DialogContent style={{ padding: "30px", paddingTop: "0" }}>

                        <FormControl fullWidth style={{ width: "100%", marginTop: "15px" }}>
                            <InputLabel id="city-select-label">Kategori Listesi</InputLabel>
                            <Select labelId="city-select-label" label="Kategori Listesi"
                                value={this.state.selectedKategoriId} id="kategori-select" style={{ background: "#fff" }}
                                onChange={this.onChangeKategoriChange} MenuProps={MenuProps}>
                                <MenuItem key={"emptykategori"} value={0}>Kategori Seçiniz</MenuItem>
                                {this.state.examinationCategoryList.map((kategori, idx) => (
                                    <MenuItem key={idx} value={kategori.categoryId}>{kategori.categoryName}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>

                        <TextField name="productname" value={this.state.productCode} label="Ürün Kodu*" style={{ width: "100%", marginTop: "10px" }}
                            onChange={this.onChangeProductCodeTextField} />

                        <TextField name="productname" value={this.state.productName} label="Ürün Adı*" style={{ width: "100%", marginTop: "10px" }}
                            onChange={this.onChangeProductNameTextField} />

                        <TextField name="productprice" value={this.state.productPrice} label="Ürün Fiyatı (TL)*" style={{ width: "100%", marginTop: "10px" }}
                            onChange={this.onChangeProductPriceTextField} type="number" />

                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.handleClickCloseUrunEkleMenu}>Kapat</Button>
                        <Button color="primary" variant='contained' onClick={this.onClickSaveProduct} >
                            Ürün Ekle
                        </Button>
                    </DialogActions>
                </Dialog>

                <Dialog
                    fullWidth={true}
                    maxWidth={"sm"}
                    open={this.state.updateProductMenuDislay}
                    onClose={this.handleClickCloseUpdateProductMenu}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description" >
                    <DialogTitle id="alert-dialog-title">
                        {"Ürün Güncelle"}
                    </DialogTitle>
                    <DialogContent style={{ padding: "30px", paddingTop: "0" }}>

                        <FormControl fullWidth style={{ width: "100%", marginTop: "15px" }}>
                            <InputLabel id="city-select-label">Kategori Listesi</InputLabel>
                            <Select labelId="city-select-label" label="Kategori Listesi"
                                value={this.state.selectedKategoriId} id="kategori-select" style={{ background: "#fff" }}
                                onChange={this.onChangeKategoriChange} MenuProps={MenuProps}>
                                <MenuItem key={"emptykategori"} value={0}>Kategori Seçiniz</MenuItem>
                                {this.state.examinationCategoryList.map((kategori, idx) => (
                                    <MenuItem key={idx} value={kategori.categoryId}>{kategori.categoryName}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>

                        <TextField name="productname" value={this.state.productCode} label="Ürün Kodu*" style={{ width: "100%", marginTop: "10px" }}
                            onChange={this.onChangeProductCodeTextField} />

                        <TextField name="productname" value={this.state.productName} label="Ürün Adı*" style={{ width: "100%", marginTop: "10px" }}
                            onChange={this.onChangeProductNameTextField} />

                        <TextField name="productprice" value={this.state.productPrice} label="Ürün Fiyatı (TL)*" style={{ width: "100%", marginTop: "10px" }}
                            onChange={this.onChangeProductPriceTextField} type="number" />

                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.handleClickCloseUpdateProductMenu}>Kapat</Button>
                        <Button color="primary" variant='contained' onClick={this.onClickUpdateProduct} >
                            Ürün Güncelle
                        </Button>
                    </DialogActions>
                </Dialog>
            </div >
        )
    }
}

export default inject("store")(observer(DashboardPage));
