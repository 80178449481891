import React, {useEffect } from 'react';
import { observer, inject } from "mobx-react"

import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';

export const ProfileMenu = inject("store")(observer((props) => {

    useEffect(() => {
        // Update the document title using the browser API
        if (props.store.firm[0] !== undefined) {

        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const onChangeTextInput = (event) => {
        /*let firm = toJS(props.store.firm[0])
        firm[event.target.name] = event.target.value
        props.store.setFirm(firm)*/
    }

    return (
        <div className="container-xxl" style={{ marginTop: "0px" }}>
            <Typography color="secondary" variant="h5" style={{ fontWeight: "bold" }}>Profilim</Typography>
            <Grid container spacing={3}>
                <Grid item xs={6} style={{ padding: "40px" }}>
                    <TextField name="firmName" label="Klinik Adı" variant="outlined" value={(props.store.firm[0] !== undefined) ? props.store.firm[0].firmName : ""}
                        onChange={onChangeTextInput} style={{ width: "100%" }} />
                    <TextField name="bankName" label="Hesap Banka Adı" variant="outlined" value={(props.store.firm[0] !== undefined) ? props.store.firm[0].bankName : ""}
                        onChange={onChangeTextInput} style={{ width: "100%", marginTop: "15px" }} />
                    <TextField name="bankUsername" label="Hesap Banka Kullanıcı Adı" variant="outlined" value={(props.store.firm[0] !== undefined) ? props.store.firm[0].bankUsername : ""}
                        onChange={onChangeTextInput} style={{ width: "100%", marginTop: "15px" }} />
                    <TextField name="iban" label="Hesap İBAN Bilgisi" variant="outlined" value={(props.store.firm[0] !== undefined) ? props.store.firm[0].iban : ""}
                        onChange={onChangeTextInput} style={{ width: "100%", marginTop: "15px" }} />
                </Grid>
                <Grid item xs={6} style={{ padding: "40px" }}>
                    <TextField name="taxNo" label="Vergi No" variant="outlined" value={(props.store.firm[0] !== undefined) ? props.store.firm[0].taxNo : ""}
                        onChange={onChangeTextInput} style={{ width: "100%", marginTop: "15px" }} />
                    <TextField name="taxAdministration" label="Vergi Dairesi" variant="outlined" value={(props.store.firm[0] !== undefined) ? props.store.firm[0].taxAdministration : ""}
                        onChange={onChangeTextInput} style={{ width: "100%", marginTop: "15px" }} />
                    <TextField name="invoiceAddress" label="Fatura Adresi" variant="outlined" value={(props.store.firm[0] !== undefined) ? props.store.firm[0].invoiceAddress : ""}
                        onChange={onChangeTextInput} style={{ width: "100%", marginTop: "15px" }} />
                    {
                        /**
                         <Button color='primary' variant='contained' style={{marginTop:"60px", float: "right"}}>
                            GÜNCELLE
                        </Button>
                         */
                    }
                </Grid>
            </Grid>
        </div>
    )
}))