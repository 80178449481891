import React, { Component } from 'react'
import { observer, inject } from "mobx-react"

import { Card, CardActions, CardContent, CardHeader } from '@mui/material';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

import { LeftMenu } from '../../components/common/LeftMenu';

import { Box, Container, Grid } from '@mui/material';

import dayjs from 'dayjs';
import getLabAccounting from '../../utils/api/tetkik/GetLabAccounting'

class MuhasebePage extends Component {

    constructor(props) {
        super(props)
        this.state = {
            transactionList: []
        }
    }

    componentDidMount = async () => {
        if (!this.props.store.isLogin) {
            this.props.store.setLoadingBarStatus(true)
            this.props.history.push("/lab/kullanici-girisi");
        } else {

            let getAccountingResponse = await getLabAccounting(this.props.store.baseUrl, this.props.store.token,
                this.props.store.user[0].id, this.props.store.firm[0].firmId)
            if (getAccountingResponse !== null && getAccountingResponse !== undefined) {
                if (getAccountingResponse.status === 200) {
                    //console.log("getAccountingResponse: ", getAccountingResponse.data)
                    this.setState({
                        transactionList: getAccountingResponse.data
                    })
                }
            }
        }
    }

    render() {

        return (
            <div className="container mps-container">
                <LeftMenu activePage={"Muhasebe"} />
                <Box component="main" sx={{
                    flexGrow: 1,
                    py: 8
                }} style={{ width: "calc(100% - 250px)", marginLeft: "250px", marginTop: "20px" }} >
                    <Container maxWidth={false}>
                        <Grid container spacing={3}>
                            <Grid item lg={12} md={12} xl={12} xs={12}>
                                <Card style={{ height: '100%' }}>
                                    <CardHeader title="Muhasebe" style={{ paddingBottom: "10px" }} />
                                    <CardContent style={{ paddingTop: "0", height: 'calc(100vh - 250px)', overflowY: "auto" }}>
                                        <TableContainer>
                                            <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                                <TableHead>
                                                    <TableRow>
                                                        <TableCell align="left">TETKİK NO</TableCell>
                                                        <TableCell align="left">DOKTOR ADI SOYADI</TableCell>
                                                        <TableCell align="left">HASTA ADI SOYADI</TableCell>
                                                        <TableCell align="left">TALEP TARİHİ</TableCell>
                                                        <TableCell align="left">GERÇEKLEŞEN TARİHİ</TableCell>
                                                        <TableCell align="left">KOMİSYON MİKTARI</TableCell>
                                                        <TableCell align="left">TETKİK BEDELİ</TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {
                                                        this.state.transactionList.map((transaction, idx) => (
                                                            <TableRow key={idx}> 
                                                                <TableCell align="left">{transaction.appointmentId}</TableCell>
                                                                <TableCell align="left">{transaction.doctorName + " " + transaction.doctorSurname}</TableCell>
                                                                <TableCell align="left">{transaction.patientName + " " + transaction.patientSurname}</TableCell>
                                                                <TableCell align="left">{dayjs(transaction.appointmentDate).format("DD.MM.YYYY HH:mm")}</TableCell>
                                                                <TableCell align="left">{dayjs(transaction.completedDate).format("DD.MM.YYYY HH:mm")}</TableCell>
                                                                <TableCell align="left">{transaction.wmCommission + transaction.doctorCommission} TL</TableCell>
                                                                <TableCell align="left">{transaction.actualPrice} TL</TableCell>
                                                            </TableRow>
                                                        ))
                                                    }

                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                    </CardContent>
                                    <CardActions >
                                    </CardActions>
                                </Card>
                            </Grid>
                        </Grid>
                    </Container>
                </Box>
            </div>
        )
    }
}

export default inject("store")(observer(MuhasebePage));
