import React, { Component } from 'react'
import { observer, inject } from "mobx-react"

import { Button, Card, CardActions, CardContent, CardHeader, Link } from '@mui/material';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

import { LeftMenu } from '../../components/common/LeftMenu';

import { Box, Container, Grid } from '@mui/material';
import { ToplamTetkikSayisi } from '../../components/tetkik/dashboard/ToplamTetkikSayisi';
import { ToplamTetkikCirosu } from '../../components/tetkik/dashboard/ToplamTetkikCirosu';
import { AylikTetkikCirosu } from '../../components/tetkik/dashboard/AylikTetkikCirosu';
import { BekleyenTetkik } from '../../components/tetkik/dashboard/BekleyenTetkik';

import dayjs from 'dayjs';
import PageviewIcon from '@mui/icons-material/Pageview';

import getLabFirmTetkikList from '../../utils/api/tetkik/GetLabFirmTetkikList'
import getLabDashboard from '../../utils/api/tetkik/GetLabDashboard'

class DashboardPage extends Component {

    constructor(props) {
        super(props)
        this.state = {
            dashboardStatics: null,
            todayRequestList: []
        }
    }

    componentDidMount = async () => {
        if (!this.props.store.isLogin) {
            this.props.store.setLoadingBarStatus(true)
            this.props.history.push("/lab/kullanici-girisi");
        } else {
            this.props.store.setLoadingBarStatus(false)
            let getLabFirmTetkikListResponse = await getLabFirmTetkikList(this.props.store.baseUrl, this.props.store.token, this.props.store.user[0].id, this.props.store.firm[0].firmId)
            console.log("getLabFirmTetkikListResponse: ", getLabFirmTetkikListResponse)
            if (getLabFirmTetkikListResponse !== null && getLabFirmTetkikListResponse !== undefined) {
                if (getLabFirmTetkikListResponse.status === 200) {
                    if (getLabFirmTetkikListResponse.data !== null) {
                        let tetkikTalepListesi = getLabFirmTetkikListResponse.data
                        tetkikTalepListesi = tetkikTalepListesi.filter(function (e) { return e.status !== 3 })
                        this.setState({
                            todayRequestList: tetkikTalepListesi
                        })
                    } else {
                        this.setState({
                            todayRequestList: []
                        })
                    }

                } else if (getLabFirmTetkikListResponse.status === 401) {
                    this.props.store.setUserLogout()
                    this.props.history.push("/lab/kullanici-girisi")
                }
            }

            let getLabDashboardResponse = await getLabDashboard(this.props.store.baseUrl, this.props.store.token, this.props.store.user[0].id, this.props.store.firm[0].firmId)
            if (getLabDashboardResponse !== null && getLabDashboardResponse !== undefined) {
                if (getLabDashboardResponse.status === 200) {
                    console.log("getLabDashboardResponse: ", getLabDashboardResponse.data)
                    this.setState({
                        dashboardStatics: getLabDashboardResponse.data
                    })
                }
            }
            this.props.store.setLoadingBarStatus(true)

        }
    }

    render() {

        return (
            <div className="container mps-container">
                <LeftMenu activePage={"Ana Sayfa"} />
                <Box component="main" sx={{
                    flexGrow: 1,
                    py: 8
                }} style={{ width: "calc(100% - 250px)", marginLeft: "250px", marginTop: "20px" }} >
                    <Container maxWidth={false}>
                        <Grid container spacing={3}>
                            <Grid item lg={3} sm={6} xl={3} xs={12}>
                                <ToplamTetkikSayisi sx={{ height: '100%' }} data={(this.state.dashboardStatics !== null) ? this.state.dashboardStatics.totalExamCount : 0} />
                            </Grid>
                            <Grid item xl={3} lg={3} sm={6} xs={12}>
                                <AylikTetkikCirosu data={(this.state.dashboardStatics !== null) ? this.state.dashboardStatics.monthlyExamIncome : 0} />
                            </Grid>
                            <Grid item xl={3} lg={3} sm={6} xs={12}>
                                <ToplamTetkikCirosu data={(this.state.dashboardStatics !== null) ? this.state.dashboardStatics.totalExamIncome : 0} />
                            </Grid>
                            <Grid item xl={3} lg={3} sm={6} xs={12}>
                                <BekleyenTetkik sx={{ height: '100%' }}
                                    data={(this.state.dashboardStatics !== null) ? this.state.dashboardStatics.waitingExamCount : 0} />
                            </Grid>
                            <Grid item lg={12} md={12} xl={12} xs={12}>
                                <Card style={{ height: '100%' }}>
                                    <CardHeader title="Gelen Tetkik Talepleri" style={{ paddingBottom: "10px" }} />
                                    <CardContent style={{ paddingTop: "0", height: 'calc(100vh - 450px)' }}>
                                        <TableContainer style={{ height: '100%' }}>
                                            <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                                <TableHead>
                                                    <TableRow>
                                                        <TableCell align="left">DOKTOR ADI SOYADI</TableCell>
                                                        <TableCell align="left">HASTA ADI SOYADI</TableCell>
                                                        <TableCell align="left">HASTA TEL. NO</TableCell>
                                                        <TableCell align="left">TALEP TARİHİ</TableCell>
                                                        <TableCell align="left">TALEP DURUMU</TableCell>
                                                        <TableCell align="left">İŞLEMLER</TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {this.state.todayRequestList.map((request, idx) => (
                                                        <TableRow key={idx}>
                                                            <TableCell align="left">{"Doktor " + request.doctorName + " " + request.doctorSurname}</TableCell>
                                                            <TableCell align="left">{request.patientName + " " + request.patientSurname}</TableCell>
                                                            <TableCell align="left">{request.patientMobilePhone}</TableCell>
                                                            <TableCell align="left">{dayjs(request.appointmentDate).format("DD.MM.YYYY HH:mm")}</TableCell>
                                                            <TableCell align="left">{request.statusName}</TableCell>
                                                            <TableCell align="left">
                                                                <Button variant='outlined' component={Link} href={"/lab/talep/" + request.appointmentId}>
                                                                    <PageviewIcon style={{ marginRight: "10px" }} /> Talep Görüntüle
                                                                </Button>
                                                            </TableCell>
                                                        </TableRow>
                                                    ))}
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                    </CardContent>
                                    <CardActions >
                                        <Button color="primary" style={{ marginLeft: "auto" }} component={Link} href='/lab/talep-listesi'>
                                            Tüm Tetkik Taleplerini Görüntüle
                                        </Button>

                                    </CardActions>
                                </Card>

                            </Grid>
                        </Grid>
                    </Container>
                </Box>
            </div>
        )
    }
}

export default inject("store")(observer(DashboardPage));
