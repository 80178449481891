import axios from "axios";

const getLabProductListCategoryById = async (baseUrl, token, labUserId, firmId, categoryId) => {

    const config = {
        headers: { Authorization: `Bearer ${token}` }
    };

    let getResponse = null

    await axios.get(baseUrl + `/lab/` + labUserId + "/product/" + firmId + "/category/" + categoryId, config)
        .then((res) => {
            console.log("res.data: ", res.data)
            getResponse = {
                status: res.status,
                data: res.data.data
            }
        }).catch((error) => {
            if (error.response !== undefined) {
                getResponse = {
                    status: error.response.status,
                    data: error.response.status
                }
            }
        });
    return getResponse
}

export default getLabProductListCategoryById;