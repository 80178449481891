import axios from "axios";
import parseFirmData from "../parsers/FirmDataParser";

const getLabFirm = async (baseUrl, token, labUserId, firmId) => {

    const config = {
        headers: { Authorization: `Bearer ${token}` }
    };

    let getResponse = null

    await axios.get(baseUrl + `/lab/` + labUserId + "/firm/" + firmId, config)
        .then((res) => {
            console.log("res.data: ", res.data)
            getResponse = {
                status: res.status,
                data: parseFirmData(res.data)
            }
        }).catch((error) => {
            if (error.response !== undefined) {
                getResponse = {
                    status: error.response.status,
                    data: error.response.status
                }
            }
        });
    return getResponse
}

export default getLabFirm;