import { User } from '../../../model/User'

/**
 * This method transform Login API response doc to User
 * @param {*} userResponse 
 */
const parseUserData = (userResponse) => {

    console.log("userResponse: ", userResponse)
    let user = null
    try {

        user = User.create({
            id: userResponse.data.id,
            name: userResponse.data.name,
            surname: userResponse.data.surname,
            email: userResponse.data.email,
            tckno: userResponse.data.tckno,
            mobilePhone: userResponse.data.mobilePhone,
            token: userResponse.data.token,
            firmId: userResponse.data.firmId,
            firmName: userResponse.data.firmName
        })

    } catch (error) {
        console.log("error: ", error)
    }

    return user;
}

export default parseUserData;

