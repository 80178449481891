import { types } from 'mobx-state-tree'
import { Firm } from '../model/Firm'

import { User } from '../model/User'

export const WholeMedicsStore = types
    .model("WholeMedicsStore", {
        baseUrl: types.string,
        isLogin: types.boolean,
        darkMode: types.boolean,
        token: types.string,
        messageInfo: types.string,
        messageDisplay: types.boolean,
        messageType: types.string,
        loadingBarStatus: types.optional(types.boolean, false),
        user: types.optional(types.array(User), []),
        firm: types.optional(types.array(Firm), [])
    }).actions(self => ({
        setUserLogged() {
            self.isLogin = true
        },
        setUserLogout() {
            self.isLogin = false
            self.token = ""
            self.user = []
            self.loadingBarStatus = true
            self.doctor = []
            self.selectedPatient = []
            self.randevuListesi = []
        },
        setDarkLightMode(inputMode) {
            self.darkMode = inputMode
        },
        setToken: (function (token) {
            self.token = token
        }),
        setMessage: (function (message, display, messageType) {
            self.messageInfo = message
            self.messageDisplay = display
            self.messageType = (messageType === undefined) ? "info" : messageType
        }),
        setLoadingBarStatus(loadingBar) {
            self.loadingBarStatus = loadingBar
        },
        setUser: (function (user) {
            self.user = []
            self.user.push(user)
        }),
        setFirm: (function (firm){
            self.firm = []
            self.firm.push(firm)
        })
    }))