import axios from "axios";

const putLabCategory = async (baseUrl, token, userId, category) => {

    const config = {
        headers: { Authorization: `Bearer ${token}` }
    };

    let putResponse = null

    await axios.put(baseUrl + "/lab/" + userId + "/category", category, config)
        .then((res) => {
            console.log("res.data: ", res.data)
            putResponse = {
                status: res.status,
                data: res.data
            }
        }).catch((error) => {
            if (error.response !== undefined) {
                putResponse = {
                    status: error.response.status,
                    data:  error.response.status
                }
            }
        });
    return putResponse
}

export default putLabCategory;